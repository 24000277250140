<template>
  <div id="pages" v-if="detail">
<!--    <a class="back" href="javascript:history.go(-1)">← 返回</a>-->
    <div class="back" @click="back">← 返回</div>
    <div class="img">
      <div class="imgn" v-cloak v-if="detail.filesUrl" style="cursor: pointer;" @click="open=true">
        <img :src="'/pic/'+detail.tpic">
        <i v-if="detail.xs==1"><img src="@/assets/play_button.gif"></i>
      </div>
      <div class="imgn" v-else>
        <img :src="'/pic/'+detail.tpic">
      </div>
    </div>
    <div class="text" :style="'background:'+detail.color">
      <div class="textn">
        <div class="time">•{{detail.mtime|toEnglish}}</div>
        <div class="title">{{detail.title}}</div>
      </div>
    </div>
    <div class="win" v-cloak v-if="detail.filesUrl&&open" @click="open=false">
      <div class="mark"></div>
      <div class="myWin">
        <div class="videoArea" v-if="detail.category==5" v-cloak>
          <iframe :src="detail.filesUrl" style="width: 100%;height: 100%;" frameborder="0"></iframe>
        </div>
        <iframe :src="'/pages/'+detail.filesUrl" style="width: 100%;height: 100%;" frameborder="0" v-else></iframe>
        <div class="close">
          <div class="closen">
            <i></i><i></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.back{
  cursor: pointer;
  width: fit-content;
}
#pages {
  background: #fff;
  min-height: 100vh;
}

.win {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.myWin {
  width: 90vw;
  height: 90vh;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: clamp(12px, 1vw, 20px);
  padding: 20px;
}

.myWin .videoArea {
  width: 90%;
  height: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: clamp(22px, 2vw, 40px);
  overflow: hidden;
}

.myWin .close {
  width: clamp(50px, 4vw, 60px);
  position: absolute;
  border-radius: 100%;
  border: solid 1px #000;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.myWin .closen {
  padding-bottom: 100%;
  position: relative;
  transform: rotate(45deg);
}

.myWin .closen i {
  width: 50%;
  height: 1px;
  background: #000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.myWin .closen i:nth-child(2) {
  width: 1px;
  height: 50%;
}

.mark {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .9);
}

#pages .back {
  color: #f60;
  padding: 12px;
  display: flex;
}

#pages .text {
  background: #fff;
  border-bottom: solid 1px #333;
  border-top: solid 1px #333;
  text-align: center;
}

#pages .imgn {
  width: 100%;
  max-width: 940px;
  margin: 0 auto;
  padding: clamp(24px, 5vw, 80px) 0;
  position: relative;
}

@media (max-width: 940px) {
  #pages .imgn {
    padding: clamp(24px, 5vw, 80px);
  }

  #pages .text .textn {
    padding: clamp(24px, 5vw, 80px) !important;
    min-height: inherit !important;
  }
}

#pages .imgn i {
  position: absolute;
  width: 20%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#pages .imgn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#pages .text .textn {
  width: 100%;
  max-width: 1296px;
  margin: 0 auto;
  padding: 0 clamp(24px, 5vw, 80px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  min-height: 306px;
}

#pages .text .textn .time {
  font-size: clamp(16px, 1vw, 18px);
}

#pages .text .textn .title {
  font-size: clamp(40px, 5vw, 80px);
}
</style>
<script>
import getPageTitle from '@/utils/getPageTitle'

export default {
    name: 'pages',
    data() {
        return {
            list: [],
            detail: null,
            open: false
        }
    },
    methods: {
        getDetail(data) {
            let fi = data.filter((el) => {
                return el.url == this.$route.params.url
            })
            if (fi.length > 0) {
                this.detail = fi[0]
                document.title = getPageTitle(this.detail.title)
            }else{
                this.$router.replace({ path: '/404' })
            }

        },
        back(){
            this.$router.back()
        }
    },
    filters: {
        toEnglish: (value) => {
            let year, month, day, cMonth
            year = value.split('-')[0]
            month = value.split('-')[1]
            day = value.split('-')[2]
            switch (month) {
                case '01':
                    cMonth = 'January';
                    break;
                case '02':
                    cMonth = 'February';
                    break;
                case '03':
                    cMonth = 'March';
                    break;
                case '04':
                    cMonth = 'April';
                    break;
                case '05':
                    cMonth = 'May';
                    break;
                case '06':
                    cMonth = 'June';
                    break;
                case '07':
                    cMonth = 'July';
                    break;
                case '08':
                    cMonth = 'August';
                    break;
                case '09':
                    cMonth = 'September';
                    break;
                case '10':
                    cMonth = 'October';
                    break;
                case '11':
                    cMonth = 'November';
                    break;
                case '12':
                    cMonth = 'December';
                    break;
            }
            return cMonth + ' ' + day + ',' + year
        },

    },
    created() {
        let sendData = {
            app_key: '1D5AF16FD7D90EE3C5CF70D088249505',
            model_name: 'game',
            perpage: 1000,
            order: ["mtime DESC", "id DESC"]
        }
        if (this.$store.state.doodles.doodles.length === 0) {
            this.$store.dispatch('doodles/getList', sendData).then((rs) => {
                this.list = rs
                this.getDetail(this.list)
            })
        } else {
            this.list = this.$store.state.doodles.doodles
            this.getDetail(this.list)
        }

    }
}
</script>

